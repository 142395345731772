<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="description" name="description" :content="description" />
    <meta property="keywords" name="keywords" :content="keywords" />
  </Head>
  <div class="flex flex-column flex-grow-1">
    <SectionHeader section="farming" :how-link="GITBOOK_LINKS.FARMING" />
    <FarmingSteps
      :action="farmingSteps.action"
      class="mb-s3"
      v-if="isFarmingStepsShown"
      @on-close="onHideSteps()"
    />

    <InfoMessage
      type="warning"
      :message="$t('wrappedContract.steps.infoMessage.someInProgress')"
      as-panel
      v-if="isSomeInProgress"
    />

    <div class="panel-frame mt-s3">
      <div class="flex flex-column md:flex-row md:align-items-center justify-content-between">
        <div class="flex justify-content-between align-items-center flex-grow-1 mb-s3">
          <div class="flex align-items-center">
            <span class="h3 text-bold">{{ $t('farming.farms.caption') }}</span>
          </div>
          <Switch
            v-if="walletState.isInjected && isFarmingStoreReady"
            :name="$t('stakedOnly')"
            name-pos="left"
            color="secondary"
            v-model="isStakedOnly"
          />
        </div>
        <div class="mb-s3 md:ml-s3" v-if="isFarmingStoreReady">
          <SortingListDropdown
            :sort-fields="sortField"
            v-model:sort-by="sortBy"
            v-model:direction="sortDirection"
            @change="onSort"
          />
        </div>
      </div>

      <template v-if="isFarmingStoreReady && sortedFarmingTableData?.length">
        <Farm
          v-for="(farm, index) in sortedFarmingTableData"
          :farm="farm"
          :key="farm.token"
          :class="{ 'mt-s3': index !== 0 }"
          @sortFarms="onSort"
          :open-after-created="
            compareAddresses(selectedFarmAddress, farm.portfolio.contractAddress)
          "
          :ref="farm.portfolio.contractAddress"
          @send-transaction="onSendTransaction"
        />
      </template>
      <div
        v-else-if="isFarmingStoreReady && sortedFarmingTableData?.length === 0"
        class="panel-list-empty"
      >
        <span>{{ $t('emptyFarmsList') }}</span>
      </div>
      <FarmsLoadingPlaceholder v-else-if="!isFarmingStoreReady || !sortedFarmingTableData" />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import Switch from '@/components/switch/Switch';
import Farm from './farm/Farm';
import { MODULE_NAMES } from '@/store';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { mapActions, mapGetters } from 'vuex';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { seo } from '@/composables/seo/useSeo';
import { Head } from '@vueuse/head';
import BigNumber from 'bignumber.js';
import roundMixin from '@/mixins/round.mixin';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import compareMixin from '@/mixins/compare.mixin';
import { asyncFnCancelable } from '@/utils/promise';
import FarmsLoadingPlaceholder from '@/views/pages/investment/farming/FarmsLoadingPlaceholder.vue';
import SortingListDropdown from '@/components/SortingListDropdown.vue';
import FarmingSteps from '@/views/pages/investment/farming/FarmingSteps.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useAllSteps } from '@/composables/useAllSteps';
import { useFarmingSteps } from '@/store/modules/farming/useFarmingSteps';
import { GITBOOK_LINKS } from '@/constants/EXTERNAL_LINKS';
import SectionHeader from '@/components/SectionHeader.vue';

export default {
  name: 'Farming',
  mixins: [roundMixin, compareMixin],
  components: {
    InfoMessage,
    FarmingSteps,
    SortingListDropdown,
    FarmsLoadingPlaceholder,
    Farm,
    Switch,
    Head,
    SectionHeader,
  },
  setup() {
    const { walletState } = useEVMWallet();
    const allStepsState = useAllSteps();
    const { farmingSteps, setIsShown, prepareSteps, runSteps } = useFarmingSteps();

    return {
      walletState,
      // farming steps
      farmingSteps,
      setIsShown,
      prepareSteps,
      runSteps,
      // all steps
      allStepsState,
    };
  },
  watch: {
    '$route.params.address': {
      handler(value) {
        if (value) {
          this.selectedFarmAddress = value;
        }
        if (this.isDataForFarmingReady) {
          nextTick(() => this.scrollToFarm(value));
        }
      },
      immediate: true,
    },
    isDataForFarmingReady: {
      handler: async function (val) {
        if (val) {
          await this.init();
          console.log('watch, this.updateInterval', this.updateInterval);
          if (!this.updateInterval) {
            this.updateInterval = setInterval(async () => {
              await this.getStatusFarms();
              await this.updateLp();
              await this.updatePortfoliosLp();
              this.onSort();
            }, UPDATE_INTERVAL);
          }
        } else {
          await this.init();
          // await this.getPairsWithBalances();
        }
        await nextTick(() => this.scrollToFarm(this.selectedFarmAddress));
      },
      immediate: true,
    },
    getFarmingTableData: {
      handler() {
        this.onSort();
      },
      immediate: true,
    },
    isStakedOnly: {
      handler() {
        this.onSort();
      },
    },
  },
  beforeUnmount() {
    this.updateController.abort();
    this.updateController = null;

    clearInterval(this.updateInterval);
    this.updateInterval = null;
  },
  data() {
    return {
      updateController: new AbortController(),
      updateInterval: null,
      isFarms: true,
      sortBy: 'apr',
      sortDirection: 'desc',
      sortField: [
        { label: this.$t('APR'), value: 'apr' },
        { label: this.$t('liquidity'), value: 'liquidity' },
        { label: this.$t('youEarned'), value: 'earned' },
      ],
      sortedFarmingTableData: null,
      isStakedOnly: false,
      selectedFarmAddress: '',
    };
  },
  async created() {
    try {
      await asyncFnCancelable(this.getTvlAmount, this.updateController.signal);

      this.updateInterval = setInterval(() => {
        this.getTvlAmount();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  methods: {
    ...mapActions(MODULE_NAMES.FARMING, {
      init: FARMING_ACTION_TYPES.FARMING_INIT,
      getStatusFarms: FARMING_ACTION_TYPES.GET_STATUS_FARMS,
      updateLp: FARMING_ACTION_TYPES.GET_FARMING_LP,
      updatePortfoliosLp: FARMING_ACTION_TYPES.GET_PORTFOLIO_FARMING_LP,
      getTvlAmount: FARMING_ACTION_TYPES.GET_CURRENT_TOTAL_TVL,
    }),
    ...mapActions(MODULE_NAMES.PORTFOLIOS, {
      getPortfolioStatistics: PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS,
    }),
    onSearch() {
      console.log(this.getFarmingTableData);
    },
    onSort() {
      let farmingData = this.getFarmingTableData;
      if (this.isStakedOnly) {
        farmingData = farmingData.filter(item => {
          return BigNumber(item.printDeposited.toSignificant(6)).isGreaterThan(0);
        });
      }
      this.sortedFarmingTableData = [
        ...farmingData.sort((prev, next) => {
          let prevValue, nextValue;

          switch (this.sortBy) {
            case 'apr':
              prevValue = prev.printIPR;
              nextValue = next.printIPR;
              break;
            case 'liquidity':
              prevValue = this.mathMixRound(prev.printLiquidity, 6);
              nextValue = this.mathMixRound(next.printLiquidity, 6);
              break;
            case 'earned':
              prevValue = prev.printEarned.toSignificant(6);
              nextValue = next.printEarned.toSignificant(6);
              break;
            default:
              break;
          }

          if (this.sortDirection === 'desc') {
            return nextValue - prevValue;
          } else {
            return prevValue - nextValue;
          }
        }),
      ];
    },
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      this.onSort();
    },
    scrollToFarm(farmAddress) {
      const el = this.$refs[farmAddress]?.[0]?.$el;
      if (el) {
        const top = el.getBoundingClientRect().top + window.pageYOffset - 60;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    },
    onShowSteps() {
      this.setIsShown(true);
    },
    onHideSteps() {
      this.setIsShown(false);
    },
    onSendTransaction() {
      if (ENABLE_FAKE_CARDANO_NETWORK) {
        this.prepareSteps();
        this.onShowSteps();
        this.runSteps();
      }
    },
  },
  computed: {
    GITBOOK_LINKS() {
      return GITBOOK_LINKS;
    },
    ...mapGetters(MODULE_NAMES.FARMING, ['getFarmingTableData']),
    isDataForFarmingReady() {
      const isPairsLoaded = this.$store.state.routes.isPairsLoaded;
      const isPortfoliosStoreReady = this.$store.state.portfolios.isStoreReady;
      return isPairsLoaded && isPortfoliosStoreReady;
    },
    isFarmingStoreReady() {
      return this.$store.state.farming.isStoreReady;
    },
    title() {
      return seo.value.SEO_FARMING.TITLE;
    },
    description() {
      return seo.value.SEO_FARMING.DESCRIPTION;
    },
    keywords() {
      return seo.value.SEO_FARMING.KEYWORDS;
    },
    isFarmingStepsShown() {
      return this.farmingSteps.isShown;
    },
    isSomeInProgress() {
      return this.allStepsState.isInProgress.state;
    },
  },
};
</script>

<style scoped></style>
